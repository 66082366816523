export default {
  save: 'Gem',
  cancel: 'Annuller',
  more: 'Flere',
  delete: 'Slet',
  close: 'Luk',
  move: 'Flyt',
  export_pdf: 'Eksporter som pdf',
  journalize_manually: 'Journaliseres manuelt',
  journalized_manually: 'Journaliseret manuelt',
  resend: 'gensend',
};
