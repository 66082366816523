export default {
  journalizing_to_esdh_system: 'Journalisering i ESDH-system',
  esdh_file_manage_page_description: 'Her kan du se alle de sikre filer, som fejlede ved afsendelse til ESDH-systemet.',
  esdh_file_manage_data_description:
    'Fejloplysningerne er også sendt til din kommunale administrator, som kan forsøge at få løst fejlene via supporten.',
  esdh_file_manage_manual_journalizing_description:
    'Såfremt dette ikke er muligt, kan filerne blive markeret til at skulle journaliseres manuelt.',
  esdh_file_manage_manual_journalizing_instruction:
    'Når du vælger én eller flere filer, får du mulighed for at {exportText} dem eller markere dem som {journalizedManuallyText}.',
  esdh_file_manage_system_page_description:
    'Her kan du se alle de sikre filer, som fejlede ved afsendelse til ESDH-systemet for institutionerne i din kommune.',
  esdh_file_manage_system_journalizing_instruction:
    'Når du vælger én eller flere filer, får du mulighed for at {resendText} dem eller markere at de skal {journalizedManuallyText}.',
  esdh_file_manage_system_journalizing_description:
    'Bliver filerne markeret til at skulle journaliseres manuelt, vil institutionsadministratoren blive adviseret herom, og filerne fjernet fra tabellen.',
  files: 'Filer',
  title: 'Titel',
  handling_status: 'Status for håndtering',
  export_status: 'Eksportstatus',
  export: 'eksportere',
  exported: 'Eksporteret',
  resend: 'gensende',
  journalized_manually: 'journaliseret manuelt',
  journalize_manually: 'journaliseres manuelt',
  unexported: 'Ikke eksporteret',
  journalized_by: 'Journaliseret af',
  sent_time: 'Afsendelsestidspunkt',
  number_of_selected: '{number} valgt',
  awaiting_municipal_admin_status: 'Afventer kommunal administrator.',
  manually_journalized_status: 'Skal journaliseres manuelt.',
  filter_all: 'Alle',
  error_code: 'Fejlkode',
  esdh_status_general_error: 'Fejl. Prøv igen.',
  esdh_status_system_error: 'Fejl i opsætning af ESDH-systemet.',
  esdh_status_file_error: 'Fejl. Filen kan ikke journaliseres. Journalisér filen manuelt.',
  esdh_status_internal_error: 'Fejl. Der arbejdes på at løse problemet hurtigst muligt.',
};
