<template>
  <div
    :title="menuTitle"
    class="menu-item"
    @click.stop="$emit('menuClick', item)"
    @keydown.enter="$emit('menuClick', item)"
    @mouseover="setMouseHover(true)"
    @mouseout="setMouseHover(false)"
  >
    <div v-if="typeof item.new != 'undefined' && item.new > 0" class="new-content-icon">
      {{ item.new }}
    </div>
    <div class="position-relative">
      <badge-notification v-if="badges > 0" :amount="badges" :border="true" />
      <img v-if="isImage" class="menu-icon" :src="menuIconImageSource" :alt="item.name" />
      <i v-else class="menu-icon" :class="menuIconImageSource" />
    </div>
    <div class="menu-text text-truncate">
      {{ menuTitle }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { menuUtil } from '../../../shared/utils/menuUtil';

export default {
  props: {
    item: Object,
    badges: { type: Number, default: 0 },
  },
  data: function () {
    return {
      institutionRole,
      isMouseHover: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
    }),
    menuTitle() {
      const menuTextKey = menuUtil.getMenuType(this.item.type);
      if (menuTextKey) {
        return this.$options.filters.fromTextKey(menuTextKey);
      }
      return this.item.name;
    },
    isWidget() {
      return this.item.widgetId != null;
    },
    isImage() {
      return menuUtil.getIsIconImage(this.menuIconImageSource);
    },
    menuIconImageSource() {
      if (this.isChild && !this.isWidget) {
        return 'static/icons/' + this.item.icon + '_child.svg';
      }
      if (this.isWidget && !this.item.icon) {
        return 'icon-Aula_widget';
      }
      if (this.isMouseHover && this.item.iconHover?.length > 0) {
        return this.item.iconHover;
      }
      return menuUtil.getMenuIconClass(this.item);
    },
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
  },
  components: {
    'badge-notification': BadgeNotification,
  },
  methods: {
    setMouseHover(hover) {
      this.isMouseHover = hover;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/breakpoints.scss';

.menu-item {
  .menu-text {
    text-transform: capitalize;
    .early-student & {
      color: #fff !important;
      text-transform: capitalize;
      font-family: Baloo, serif;
      font-weight: normal;
    }
  }
  /deep/ .badge-notification {
    .badge span {
      .early-student & {
        top: -6px !important;
        left: -1px !important;
      }
    }
    @include breakpoint-lg-down() {
      .early-student & {
        margin-top: -2px;
        margin-left: 3px;
      }
      .badge span {
        .early-student & {
          left: 0 !important;
        }
      }
    }
  }
}
</style>
