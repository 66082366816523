// Onboarding flow
export const CONSENT_ANSWER_TRUE = 'Ja';
export const CONSENT_ANSWER_ACCEPTED = 'Ja';
export const CONSENT_ANSWER_DECLINED = 'Nej';
export const CONSENT_ANSWER_YES_FOR = 'Ja for';
export const CONSENT_ANSWER_INSTITUTION = 'Institution';
export const CONSENT_ANSWER_CLASS_OR_STUE = 'Klasse eller stue';
export const CONSENT_ANSWER_YEAR_AND_SFO = 'Årgang';
export const CONSENT_ANSWER_NOT_ANSWERED = 'Ikke besvaret';
export const CONSENT_ANSWER_NOT_AT_ALL = 'Nej';
export const CONSENT_ANSWER_FALSE = 'Nej';
export const ADDITIONAL_ANSWER_YES = 'Ja';
export const ADDITIONAL_ANSWER_NO = 'Nej';
export const ADDITIONAL_ANSWER_TRUE = 'Ja';
export const ADDITIONAL_ANSWER_FALSE = 'Nej';
export const NO_CONSENT_TO_SHOW = 'Samtykker er ikke besvaret';
export const NO_ADDITIONAL_CONSENT_TO_SHOW = 'Der er ingen stamkortoplysninger at vise';
export const CONSENT_LABEL = 'Deling af kontaktoplysninger';
export const ARIA_LABEL_CHOOSE_ALL_YES = 'Vælg ja for alle for {profileName} på {institutionName}';
export const ARIA_LABEL_CHOOSE_ALL_NO = 'Vælg nej for alle for {profileName} på {institutionName}';
export const ARIA_LABEL_CHECK_YES = 'Spørgsmål: {question}. Vælg ja for {profileName} på {institutionName}';
export const ARIA_LABEL_CHECK_NO = 'Spørgsmål: {question}. Vælg nej for {profileName} på {institutionName}';
export const ADDITIONAL_DATA_MASTER_DATA_LABEL = 'Supplerende stamdata';
export const ADDITIONAL_LABEL = 'Stamkort';
export const ADDITIONAL_INFO_LABEL = 'Stamkortoplysninger';
export const ADDITIONAL_DATA_VIEW_REVISION_HITORY = 'SE REVISIONHISTORIK';
export const ADDITIONAL_DATA_EDITED_BY = 'Redigeret af';
export const ADDITIONAL_DATA_EDITED_AT = 'd.';
export const CONTACT_INFO = 'Kontaktoplysninger';
export const CONTACT_INFORMATION = 'Oplysninger';
export const CONTACT_SHARED_INFORMATION = 'Deling af oplysninger';
export const CONTACTS_LABEL = 'Kontakter';
export const CONTACTS_TITLE = 'Kontaktoplysninger';
export const APP_SETTINGS_LABEL = 'App historik';
export const APP_SETTINGS_SUBLABEL =
  'Her kan du se en oversigt over hvornår og fra hvilken enhed, du er logget på appen';
export const GENERAL_CONDITIONS_LABEL = 'Generelle betingelser';
export const SHARING_CONSENT_CLASS_OR_DAYCARE_ROOM = 'Klasse eller stue';
export const SHARING_CONSENT_YEAR_AND_SFO = 'Årgang';
export const SHARING_CONSENT_INSTITUTION = 'Institution';
export const COMPLEX_CONSENT_CLASS_OR_STUE = 'Klasse eller stue';
export const COMPLEX_CONSENT_YEAR_AND_SFO = 'Årgang';
export const COMPLEX_CONSENT_INSTITUTION = 'Institution';
export const CONSENT_TIME_SPAN = 'Perioden';
export const CONSENT_FROM = 'fra';
export const CONSENT_TO = 'til';
export const CONSENT_CANCEL_MODAL_TEXT1 = 'Ændringer vil ikke blive gemt.';
export const CONSENT_CANCEL_MODAL_TEXT2 = 'Er du sikker på, du vil forlade siden?';
export const CONSENT_INVALID_MODAL_TEXT1 =
  'Stamdata kan ikke gemmes fordi der mangler at blive udfyldt nogle oplysninger';
export const CONSENT_INVALID_MODAL_TEXT2 = 'Udfyld felterne markeret med rødt og tryk på gem igen';
export const ADDITIONAL_DATA_SAVED_SUCCESS = 'Dine ændringer er gemt';

export const CONSENT_WARNING_DECLINED_2 = 'Er du sikker på, du vil fortsætte?';

export const HELP_TEXT_TITLE = 'Info';
export const CONSENT_HELPTEXT_2 =
  '<p><strong>Hvis du er for&aelig;lder:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>\n' +
  '<p><strong>Hvis du svarer p&aring; vegne af dine b&oslash;rn:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>\n' +
  '<p><strong>Hvis du er barn og gammel nok til at give dit eget samtykke:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk! </strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>';
export const CONSENT_HELPTEXT_3 =
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>';
export const CONSENT_HELPTEXT_11 =
  '<p><strong>Hvis du svarer p&aring; vegne af dine b&oslash;rn:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>\n' +
  '<p><strong>Hvis du er barn og gammel nok til at give dit eget samtykke:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk! </strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>';
export const CONSENT_HELPTEXT_32 =
  '<p><strong>Hvis du er for&aelig;lder:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>\n' +
  '<p><strong>Hvis du svarer p&aring; vegne af dine b&oslash;rn:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>\n' +
  '<p><strong>Hvis du er barn og gammel nok til at give dit eget samtykke:</strong></p>\n' +
  '<p><strong>Bem&aelig;rk!</strong></p>\n' +
  '<p>Du kan altid &aelig;ndre eller tr&aelig;kke dit samtykke tilbage under menupunktet Min profil.</p>';
export const CONSENT_HELPTEXT_29 =
  '<b>Hvis du svarer på vegne af dine børn:</b><br/>Svarer du ja til dette samtykke, vil dit barns kontaktoplysninger blive vist for børn og forældre i dit barns klasse eller stue, årgang eller for hele institutionen. Vær opmærksom på, at årgang kun vises for skoler. Kontaktoplysningerne består af navn, adresse, tlf.nr. og e-mailadresse. Bemærk, at kontaktoplysninger altid deles med medarbejderne på institutionen uafhængigt af dette samtykke. Svarer du nej til samtykket, bliver kontaktoplysningerne kun vist for institutionens medarbejdere. Du kan på ethvert tidspunkt ændre eller trække dit samtykke tilbage under menupunktet Min profil, hvor du også kan læse mere om dine rettigheder i privatlivspolitikken.<br/><br/><b>Hvis du er barn og gammel nok til at give dit eget samtykke:</b><br/>Svarer du ja til dette samtykke, vil dine kontaktoplysninger blive vist for børn og forældre i din klasse eller stue, årgang eller for hele institutionen. Kontaktoplysningerne består af navn, adresse, tlf.nr. og e-mailadresse. Bemærk, at kontaktoplysninger altid deles med medarbejderne på institutionen uafhængigt af dette samtykke. Svarer du nej til samtykket, bliver kontaktoplysningerne kun vist for institutionens medarbejdere. Du kan på ethvert tidspunkt ændre eller trække dit samtykke tilbage under menupunktet Min profil, hvor du også kan læse mere om dine rettigheder i privatlivspolitikken.';
export const CONSENT_HELPTEXT_15 =
  '<b>Hvis du er forælder:</b><br/>Svarer du ja til dette samtykke, vil dine kontaktoplysninger blive vist for børn og forældre i dit barns klasse eller stue, årgang eller for hele institutionen. Vær opmærksom på, at årgang kun vises for skoler. Kontaktoplysningerne består af navn, adresse, tlf.nr. og e-mailadresse. Bemærk, at kontaktoplysninger altid deles med medarbejderne på institutionen uafhængigt af dette samtykke. Svarer du nej til samtykket, bliver kontaktoplysningerne kun vist for institutionens medarbejdere. Du kan på ethvert tidspunkt ændre eller trække dit samtykke tilbage under menupunktet Min profil, hvor du også kan læse mere om dine rettigheder i privatlivspolitikken.';
