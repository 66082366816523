export default {
  draft: 'kladde',
  thread: '{count} beskedtråd | {count} beskedtråde',
  created_by_you: 'Oprettet af dig',
  select_this_message: 'Vælg denne meddelelse',
  sensitive_content: 'Følsomt indhold',
  read: 'Læst',
  unread: 'Ulæst',
  remove_star: 'Fjern stjerne',
  mark_with_star: 'Marker med stjerne',
  remove_mark: 'Fjern stjerne',
  mute: 'Slå notifikationer fra',
  un_mute: 'Slå notifikationer til',
  forward: 'Videresend',
  thread_metadata_1: 'Samtalen er startet af {creator} ',
  thread_metadata_2: '{startedAt}',
  common_inboxes: 'Fællespostkasser',
  move: 'Flyt',
  add_more_recipients: 'Tilføj flere modtagere',
  response_option: 'Svarmuligheder',
  delete: 'Slet',
  functionalities: 'Besked funktionaliteter',
};
