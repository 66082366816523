import messages from './messages';
import calendar from './calendar';
import button from './button';
import user from './user';
import document from './document';
import contact from './contact';
import settings from './settings';
import label from './label';
import warning from './warning';

export default {
  messages,
  calendar,
  button,
  user,
  document,
  contact,
  settings,
  label,
  warning,
};
