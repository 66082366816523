import axios from 'axios';

const requestCancelTokenMixin = {
  methods: {
    cancelAxiosRequest(cancelTokenSource, message = 'Request cancelled') {
      if (cancelTokenSource == null) {
        return;
      }
      cancelTokenSource.cancel(message);
    },
    getAxiosCancelTokenSource() {
      return axios.CancelToken.source();
    },
  },
};

export default requestCancelTokenMixin;
