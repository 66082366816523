<template>
  <b-input-group class="aula-search-input" :class="color">
    <b-form-input
      ref="searchInput"
      type="text"
      :value="value"
      v-bind="{ ...$attrs }"
      v-on="$listeners"
      @keyup.native.enter="onSearchClicked"
    />
    <b-input-group-prepend>
      <b-btn v-show="value" variant="link" @click="onClearClicked">
        <i class="icon icon-Aula_close" />
        <span class="sr-only">{{ 'ARIA_LABEL_DELETE' | fromTextKey }}</span>
      </b-btn>
      <b-btn variant="link" @click="onSearchClicked">
        <i class="icon icon-Aula_search" />
        <span class="sr-only">{{ 'ARIA_LABEL_SEARCH' | fromTextKey }}</span>
      </b-btn>
      <slot name="prepend" />
    </b-input-group-prepend>
  </b-input-group>
</template>
<script>
export default {
  props: {
    value: { type: String, require: true },
    color: { type: String, default: 'valid', validator: value => ['valid', 'invalid'].includes(value) },
  },
  methods: {
    onClearClicked() {
      this.$refs.searchInput.focus();
      this.$emit('onClearClicked');
    },
    onSearchClicked() {
      this.$emit('onSearchClicked');
    },
  },
};
</script>
<style scoped lang="scss">
.input-group.invalid {
  --input-group-border-width: 1px;
  --input-group-border-style: solid;
  --input-group-border-color: var(--color-alert);
}
.input-group-prepend {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--input-background-color, var(--color-white));
  gap: 0.5rem;
  padding: 0 0.5rem;
  margin-right: unset;
}
i.icon {
  font-size: 0.75rem;
  &.icon-Aula_search {
    font-size: 1.25rem;
  }
}
</style>
