export default {
  journalise_to_esdh_system: 'Journaliser i ESDH-system',
  no_esdh_system_specified: 'Din kommune har endnu ikke opsat et modtagende ESDH-system.',
  exceeded_regarding_children_warning_1:
    'Følgende filer kan ikke blive sendt til journalisering i ESDH-system, fordi de omhandler flere end 28 børn.',
  exceeded_regarding_children_warning_2:
    'Nævnte filer bliver fjernet fra listen og bliver ikke sendt til journalisering i ESDH-system. Vil du fortsætte?',
  sending_documents_to_esdh_system_information: 'Du er ved at sende følgende filer til din kommunes ESDH-system.',
  total_selected_files: 'Antal filer markeret til journalisering i ESDH-system: {total}',
  document_esdh_title: 'Titel',
  document_esdh_description: 'Beskrivelse',
  document_esdh_regarding_children: 'Barn',
  document_esdh_edited_time_stamp: 'Redigeret',
  document_esdh_total_children: '{firstChildName} ({firstChildMetadata}) + {additionalRegardingChildren} andre',
  aria_label_fold_out_button: 'Fold ud',
  journalization_in_progress: 'Journalisering i gang',
  journalization_completed: 'Filen er journaliseret i ESDH-systemet',
  journalization_failed: 'Journalisering fejlede',
  document_esdh_exceeded_regarding_children_warning_1:
    'Følgende filer kan ikke blive sendt til journalisering i ESDH-system, fordi de omhandler flere end 28 børn.',
  document_esdh_exceeded_regarding_children_warning_2:
    'Nævnte filer bliver fjernet fra listen og bliver ikke sendt til journalisering i ESDH-system.',
  document_esdh_no_regarding_children_warning_1:
    'Følgende filer kan ikke sendes til journalisering i ESDH, fordi der ikke er mindst et barn tilknyttet.',
  document_esdh_no_regarding_children_warning_2:
    'De filer bliver derfor fjernet fra listen af filer, der bliver journaliseret i ESDH-system.',
  document_esdh_you_are_about_to_send: 'Du er ved at sende ',
  document_esdh_file_to_esdh_system: ' filer til ESDH-system.',
  document_esdh_file_will_then_remain: 'Filerne vil derefter blive ',
  document_esdh_sending_confirm_message_locked: 'låst,',
  document_esdh_can_not_edit_after_sending: ' hvilket betyder, at du IKKE vil kunne redigere filerne efter afsendelse.',
  document_esdh_are_you_sure_to_continue: 'Er du sikker på, at du vil fortsætte?',
  unlock_being_exported_document_warning_message:
    'Denne fil er i gang med at blive journaliseret, derfor kan den ikke låses op på nuværende tidspunkt.',
  document_esdh_export_being_journalized_again_warning_1:
    'Det er ikke muligt at journalisere dokumenter, der er i gang med at blive journaliseret.',
  document_esdh_export_being_journalized_again_warning_2:
    'Nævnte filer er allerede sendt til journalisering i ESDH-systemet og bliver derfor fjernet fra listen.',
  file_is_being_journalized: 'Filen er under journalisering',
  can_not_unlock_file_being_exported:
    'Denne fil er i gang med at blive journaliseret, derfor kan den ikke låses op på nuværende tidspunkt.',
  can_not_journalize_already_journalized_document:
    'Denne fil er allerede journaliseret og kan ikke blive journaliseret igen.',
  dont_have_permission_to_export_in_given_institution_warning_1:
    'Du har valgt en eller flere filer, du ikke har rettighed til at journalisere, som vil blive fjernet fra eksportlisten.',
  dont_have_permission_to_export_in_given_institution_warning_2: 'Vil du fortsætte uden nævnte filer?',
  no_recipients_configured_in_given_municipality_warning_1:
    'Du har valgt en eller flere filer, i en kommune hvor journalisering ikke er sat op af en administrator, hvorfor nedenstående filer bliver fjernet fra journaliseringslisten.',
  no_recipients_configured_in_given_municipality_warning_2: 'Vil du fortsætte uden nævnte filer?',
  no_files: 'Der er ingen filer.',
  functionalities: 'Fil funktionaliteter',
};
