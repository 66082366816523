<template>
  <div class="reported-content-media-preview">
    <AulaImg v-if="media.mediaType == mediaTypes.IMAGE" class="img-fluid" :img-url="media.file.url" />
    <video
      v-if="media.mediaType == mediaTypes.VIDEO"
      slot="img"
      class="video"
      :src="media.file.url"
      controls
      controlsList="nodownload"
    />
    <audio v-if="media.mediaType == mediaTypes.SOUND" slot="img" class="audio" :src="media.file.url" controls />
    <h2>{{ media.title }}</h2>
    {{ 'GALLERY_CREATOR' | fromTextKey }}: {{ media.creator | displayProfileNameWithMetadata }}<br />
    {{ 'GALLERY_CREATED' | fromTextKey }}: {{ media.file.created | longDate(true) }}<br />
    {{ media.description }}<br v-if="media.description" />
    <template v-if="media.tags != null && media.tags.length > 0">
      {{ 'GALLERY_TAGS' | fromTextKey }}:
      {{ media.tags.map(tag => Vue.filter('displayProfileNameWithMetadata')(tag)).join(', ') }}
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mediaTypes } from '../enums/mediaTypes';
import AulaImg from './AulaImg';

export default {
  components: { AulaImg },
  props: {
    media: { type: Object, default: null },
  },
  data: function () {
    return {
      mediaTypes: mediaTypes,
      Vue: Vue,
    };
  },
};
</script>
