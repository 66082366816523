// COMMENTS
export const COMMENTS_CHOOSE_INSTITUTION = 'Vælg institution du vil skrive kommentar fra';
export const COMMENTS_COMMENT_IS_DELETED = 'Kommentaren er slettet';
export const COMMENTS_COMMENT_IS_UPDATED = 'Kommentaren er opdateret';
export const COMMENTS_COMMENT_IS_REPORTED = 'Kommentaren er anmeldt';
export const COMMENTS_COMMENT_DELETE = 'Slet';
export const COMMENTS_COMMENT_REPORT = 'Anmeld';
export const COMMENTS_COMMENT_EDIT = 'Rediger';
export const COMMENTS_CONFIRM_DELETE = 'Du er ved at slette kommentaren.';
export const COMMENTS_CONFIRM_DELETE_2 = 'Er du sikker på, at du vil slette den?';
export const COMMENTS_CONFIRM_CANCEL_COMMENT = 'Din kommentar vil ikke blive gemt.';
export const COMMENTS_CONFIRM_CANCEL_COMMENT_2 = 'Er du sikker på, at du vil fortsætte?';
export const COMMENTS_COMMENT = 'kommentar';
export const COMMENTS_COMMENTS = 'kommentarer';
export const COMMENTS_WRITE_COMMENT = 'Skriv kommentar';
export const COMMENTS_REPLYING_TO_COMMENT = 'Du svarer på {name} kommentar';
export const COMMENTS_EDITING_COMMENT = 'Rediger kommentar';
export const COMMENTS_WRITE_REPLY = 'Skriv svar';
export const COMMENTS_SHOW_EARLIER_COMMENTS = 'Vis tidligere kommentarer';
export const COMMENTS_SHOW_EARLIER_REPLYS = 'Vis tidligere svar';
export const COMMENTS_COMMENT_IS_EDITED = 'Redigeret';
export const ALL_USER_DATA_START_DATE_IS_AFTER_END_DATE = 'Startdato skal være før slutdato';
export const ALL_USER_MISSING_START_DATE = 'Du mangler at angive startdato';
export const ALL_USER_MISSING_END_DATE = 'Du mangler at angive slutdato';
