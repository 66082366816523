<template>
  <div class="administration-main-content reported-content-container page-layout-padding" :class="{ group: group }">
    <h1 :class="{ h1: !group }">
      {{ group ? 'REPORTED_CONTENT_TITLE_GROUP' : 'REPORTED_CONTENT_TITLE' | fromTextKey }}
    </h1>
    <p v-if="!group" class="headline-margin-bottom">
      {{ 'REPORTED_CONTENT_DESCRIPTION' | fromTextKey }}
    </p>
    <h2>{{ 'REPORTED_CONTENT_TITLE_POSTS' | fromTextKey }}</h2>
    <aula-table
      v-if="isProfileLoaded && paramInstitutions != null"
      ref="reportedPostsResult"
      :per-page="perPage"
      :current-page="1"
      :fields="fields"
      table-ref="reportedPostsResult"
      initial-sort-by="reportedTime"
      :initial-sort-desc="true"
      empty-table-filtered-text="MESSAGE_REPORTED_CONTENT_EMPTY_TABLE"
      empty-table-text="MESSAGE_REPORTED_CONTENT_EMPTY_TABLE"
      :show-pagination="true"
      :prepare-params="preparePostParams"
      :prepare-items="preparePostItems"
      :server-side="true"
      server-side-url="?method=posts.getReportedPosts"
      :is-http-post="true"
      @acceptReportedPostConfirm="acceptReportedPostConfirm"
      @rejectReportedPostConfirm="rejectReportedPostConfirm"
      @previewPost="previewPost"
    />
    <div class="clearfix" />
    <h2 class="mt-5">
      {{ 'REPORTED_CONTENT_TITLE_MEDIA' | fromTextKey }}
    </h2>
    <aula-table
      v-if="isProfileLoaded && paramInstitutions != null"
      ref="reportedMediaResult"
      :per-page="perPage"
      :current-page="1"
      :responsive="isMobile"
      :fields="fields"
      table-ref="reportedMediaResult"
      initial-sort-by="reportedTime"
      :initial-sort-desc="true"
      empty-table-filtered-text="MESSAGE_REPORTED_CONTENT_EMPTY_TABLE"
      empty-table-text="MESSAGE_REPORTED_CONTENT_EMPTY_TABLE"
      :show-pagination="true"
      :prepare-params="prepareMediaParams"
      :prepare-items="prepareMediaItems"
      :server-side="true"
      server-side-url="?method=gallery.getReportedMedia"
      :is-http-post="true"
      @acceptReportedMediaConfirm="acceptReportedMediaConfirm"
      @rejectReportedMediaConfirm="rejectReportedMediaConfirm"
      @previewMedia="previewMedia"
    />
    <div class="clearfix" />
    <h2 class="mt-5">
      {{ 'REPORTED_CONTENT_TITLE_COMMENTS' | fromTextKey }}
    </h2>
    <aula-table
      v-if="isProfileLoaded && paramInstitutions != null"
      ref="reportedCommentsResult"
      :per-page="perPage"
      :current-page="1"
      :responsive="isMobile"
      :fields="fields"
      table-ref="reportedCommentsResult"
      initial-sort-by="reportedTime"
      :initial-sort-desc="true"
      empty-table-filtered-text="MESSAGE_REPORTED_CONTENT_EMPTY_TABLE"
      empty-table-text="MESSAGE_REPORTED_CONTENT_EMPTY_TABLE"
      :show-pagination="true"
      :prepare-params="prepareCommentParams"
      :prepare-items="prepareCommentItems"
      :server-side="true"
      server-side-url="?method=comments.getReportedComments"
      :is-http-post="true"
      @acceptReportedCommentConfirm="acceptReportedCommentConfirm"
      @rejectReportedCommentConfirm="rejectReportedCommentConfirm"
      @previewComment="previewComment"
    />
    <aula-modal
      ref="commentModal"
      header-text="REPORTED_CONTENT_POST_BOX_TITLE"
      cancel-text="REPORTED_CONTENT_FIELDS_REJECT"
      ok-text="REPORTED_CONTENT_FIELDS_ACCEPT"
      css-class="aula-modal-lg"
      @cancelClicked="rejectReportedCommentConfirm()"
      @okClicked="acceptReportedCommentConfirm()"
    >
      <div v-if="selectedComment != null" class="preview-container">
        <Post
          v-if="selectedComment.postId != null"
          :post="relatedPost"
          preview-mode
          includes-reported-comments
          :highlighted-comment-id="selectedCommentId"
        />
        <media-detail
          v-if="showPostMediaDetail && relatedPostMedia.length > 0"
          :album="relatedPostMedia"
          :parent="parentTypes.ADMINISTRATION"
          @closeMediaDetail="toggleMediaDetailPopup"
        />
        <div v-else-if="selectedComment.mediaId" class="preview-container">
          <reported-content-media-preview :media="relatedMedia" />
          <comments
            :parent-id="selectedComment.mediaId"
            :show-reported-comments="true"
            :highlight-comment="highlightComment"
            :parent-type="commentTypes.MEDIA"
            :comment-count="relatedMedia.commentCount"
          />
        </div>
      </div>
    </aula-modal>
    <aula-modal
      ref="postModal"
      header-text="REPORTED_CONTENT_POST_BOX_TITLE"
      cancel-text="REPORTED_CONTENT_FIELDS_REJECT"
      ok-text="REPORTED_CONTENT_FIELDS_ACCEPT"
      css-class="aula-modal-lg"
      @cancelClicked="isMobile ? $refs.postModal.hide() : rejectReportedPostConfirm()"
      @okClicked="acceptReportedPostConfirm()"
    >
      <div v-if="selectedPost" class="preview-container">
        <Post
          :key="selectedPost.id"
          :post="selectedPost"
          :highlighted-comment-id="selectedCommentId"
          preview-mode
          includes-reported-comments
        />
        <media-detail
          v-if="showPostMediaDetail && relatedPostMedia.length > 0"
          :album="relatedPostMedia"
          :parent="parentTypes.ADMINISTRATION"
          @closeMediaDetail="toggleMediaDetailPopup"
        />
      </div>
    </aula-modal>
    <aula-modal
      ref="mediaModal"
      header-text="REPORTED_CONTENT_POST_BOX_TITLE"
      cancel-text="REPORTED_CONTENT_FIELDS_REJECT"
      ok-text="REPORTED_CONTENT_FIELDS_ACCEPT"
      @cancelClicked="isMobile ? $refs.mediaModal.hide() : rejectReportedMediaConfirm()"
      @okClicked="acceptReportedMediaConfirm()"
    >
      <div v-if="selectedMedia" class="preview-container">
        <reported-content-media-preview :media="selectedMedia" />
      </div>
    </aula-modal>
    <aula-modal
      ref="deletePostModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deletePostModal.hide(), $refs.postModal.hide(), (selectedPost = null)"
      @okClicked="acceptReportedPost"
    >
      {{ 'REPORTED_CONTENT_DELETE_CONFIRMATION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteMediaModal"
      ok-text="BUTTON_YES"
      @cancelClicked="
        $refs.deleteMediaModal.hide(), $refs.postModal.hide(), $refs.mediaModal.hide(), (selectedMedia = null)
      "
      @okClicked="acceptReportedMedia"
    >
      {{ 'REPORTED_CONTENT_DELETE_CONFIRMATION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteCommentModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deleteCommentModal.hide(), $refs.commentModal.hide(), (selectedComment = null)"
      @okClicked="acceptReportedComment"
    >
      {{ 'REPORTED_CONTENT_DELETE_CONFIRMATION' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../src_adm/store/types/types';
import { institutionTypes } from '../enums/institutionTypes';
import { notificationTypes } from '../enums/notificationTypes';
import { parentTypes } from '../enums/parentTypes.ts';
import MediaDetail from '../../src/components/gallery/MediaDetail';
import { commentTypes } from '../enums/commentTypes';
import Comments from '../../shared/components/Comments';
import { notificationAreas } from '../enums/notificationAreas';
import { notificationEventTypes } from '../enums/notificationEventTypes';
import ReportedContentMediaPreview from './ReportedContentMediaPreview';
import Post from '../../src/components/posts/Post.vue';

export default {
  components: {
    Post,
    ReportedContentMediaPreview,
    MediaDetail,
    Comments,
  },
  props: {
    group: { type: Object, default: null },
  },
  data: function () {
    return {
      parentTypes: parentTypes,
      Vue: Vue,
      commentTypes: commentTypes,
      perPage: 20,
      selectedPost: null,
      selectedMedia: null,
      selectedComment: null,
      reportedPostItems: [],
      reportedMediaItems: [],
      reportedCommentItems: [],
      paramInstitutions: null,
      showPostStartDateAfterEndDateWarning: false,
      showMediaStartDateAfterEndDateWarning: false,
      showCommentStartDateAfterEndDateWarning: false,
      fields: {
        reporter: {
          label: this.$options.filters.fromTextKey('REPORTED_CONTENT_FIELDS_REPORTER'),
          class: 'reporter',
        },
        reportedTime: {
          label: this.$options.filters.fromTextKey('REPORTED_CONTENT_FIELDS_DATE'),
          class: 'date',
          formatter: unixDateTime => this.$options.filters.shortDate(unixDateTime, true, true),
        },
        link: {
          label: this.$options.filters.fromTextKey('REPORTED_CONTENT_FIELDS_LINK'),
          class: 'link',
          type: 'action',
        },
        reason: {
          label: this.$options.filters.fromTextKey('REPORTED_CONTENT_FIELDS_REASON'),
          class: 'reason',
        },
        author: {
          label: this.$options.filters.fromTextKey('REPORTED_CONTENT_FIELDS_AUTHOR'),
          class: 'author',
        },
        actions: {
          label: this.$options.filters.fromTextKey('REPORTED_CONTENT_FIELDS_HANDLE_REPORT'),
          class: 'handleReport',
          type: 'action',
        },
      },
      highlightComment: {},
      showPostMediaDetail: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      isMobile: types.GET_IS_MOBILE,
      relatedPost: types.GET_RELATED_POST,
      relatedMedia: types.GET_RELATED_MEDIA,
      notifications: types.GET_NOTIFICATIONS,
    }),
    selectedCommentId() {
      return this.selectedComment?.id || null;
    },
    institutionCode() {
      if (this.group != null) {
        return this.group.institutionCode;
      } else {
        return this.selectedInstitution.institutionCode;
      }
    },
    relatedPostMedia() {
      return this.relatedPost != null
        ? this.relatedPost.attachments.filter(att => att.media != null).map(att => att.media)
        : [];
    },
  },
  mounted() {
    this.loadParamInstitutions();
  },
  methods: {
    ...mapActions({
      loadInstitutionsForMunicipality: types.LOAD_ADMINISTRATIVE_AUTHORITIES,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      removeReportedPost: types.REMOVE_REPORTED_POST,
      removeReportedMedia: types.REMOVE_REPORTED_MEDIA,
      removeReportedComment: types.REMOVE_REPORTED_COMMENT,
      rejectReportedPost: types.REJECT_REPORTED_POST,
      rejectReportedMedia: types.REJECT_REPORTED_MEDIA,
      rejectReportedComment: types.REJECT_REPORTED_COMMENT,
      toggleSpinner: types.TOGGLE_SPINNER,
      loadRelatedPost: types.LOAD_RELATED_POST_BY_ID,
      loadRelatedMedia: types.LOAD_RELATED_MEDIA_BY_ID,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    deletePostNotifications(postId) {
      const notifications = this.notifications
        .filter(n => n.notificationArea === notificationAreas.ADMINISTRATION && n.postId === postId)
        .map(n => ({
          notificationId: n.notificationId,
          institutionProfileId: n.institutionProfileId,
        }));
      if (notifications.length > 0) {
        this.deleteNotifications({ notifications: notifications }).then(() => {
          this.$refs.reportedPostsResult.refresh();
        });
      }
    },
    deleteCommentNotifications(commentId) {
      const notifications = this.notifications
        .filter(n => n.notificationArea === notificationAreas.ADMINISTRATION && n.commentId === commentId)
        .map(n => ({
          notificationId: n.notificationId,
          institutionProfileId: n.institutionProfileId,
        }));
      if (notifications.length > 0) {
        this.deleteNotifications({ notifications: notifications }).then(() => {
          this.$refs.reportedCommentsResult.refresh();
        });
      }
    },
    deleteMediaNotifications(mediaId) {
      const notifications = this.notifications
        .filter(n => n.notificationArea === notificationAreas.ADMINISTRATION && n.mediaId === mediaId)
        .map(n => ({
          notificationId: n.notificationId,
          institutionProfileId: n.institutionProfileId,
        }));
      if (notifications.length > 0) {
        this.deleteNotifications({ notifications: notifications }).then(() => {
          this.$refs.reportedMediaResult.refresh();
        });
      }
    },
    async loadParamInstitutions() {
      if (this.group != null && this.group.institutionCode != null) {
        this.paramInstitutions = [];
      } else {
        let institutions = [this.selectedInstitution.institutionCode];
        if (this.selectedInstitution.institutionType === institutionTypes.MUNICIPALITY) {
          await this.loadInstitutionsForMunicipality(this.selectedInstitution.municipalityCode).then(rsp => {
            institutions = institutions.concat(rsp.map(inst => inst.institutionCode));
          });
        }
        this.paramInstitutions = institutions;
      }
    },
    toggleMediaDetailPopup(mediaId) {
      if (!this.showPostMediaDetail) {
        this.$router.push({
          name: 'viewMediaInReportedContent',
          params: { mediaId: mediaId },
        });
      } else {
        this.$router.push({ name: 'ReportedContent' });
      }
      this.showPostMediaDetail = !this.showPostMediaDetail;
    },
    preparePostParams(ctx) {
      let params = {};
      if (this.group != null && this.group.institutionCode != null) {
        params = {
          institutionCodes: [this.group.institutionCode],
          groupId: this.group.id,
          startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
          limit: ctx.perPage,
        };
      } else if (this.selectedInstitution != null && this.selectedInstitution.institutionCode != null) {
        params = {
          institutionCodes: this.paramInstitutions,
          startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
          limit: ctx.perPage,
        };
      }
      return params;
    },
    preparePostItems(response) {
      const reportedPosts = response.data.data.reportedPosts;
      this.reportedPostItems = reportedPosts.map(current => {
        const reportedContentItem = Object.assign({}, current);
        reportedContentItem.hasBadge =
          this.notifications.find(
            n =>
              n.postId === reportedContentItem.id &&
              n.notificationType === notificationTypes.BADGE &&
              n.notificationEventType === notificationEventTypes.POST_WAS_REPORTED
          ) != null;
        reportedContentItem.reporter = Vue.filter('displayProfileNameWithMetadata')(
          reportedContentItem.postReport.reportedBy
        );
        reportedContentItem.reportedTime = reportedContentItem.postReport.reportedAt;
        reportedContentItem.author = Vue.filter('displayProfileNameWithMetadata')(reportedContentItem.ownerProfile);
        reportedContentItem.link = [
          {
            textkey: 'REPORTED_CONTENT_LINK_LABEL',
            variant: 'link',
            method: 'previewPost',
          },
        ];
        reportedContentItem.reason = reportedContentItem.postReport.reason;
        reportedContentItem.actions = [
          {
            variant: 'link',
            method: 'acceptReportedPostConfirm',
            textkey: 'REPORTED_CONTENT_FIELDS_ACCEPT',
          },
          {
            variant: 'link',
            method: 'rejectReportedPostConfirm',
            textkey: 'REPORTED_CONTENT_FIELDS_REJECT',
          },
        ];
        return reportedContentItem;
      });
      return this.reportedPostItems;
    },
    prepareMediaParams(ctx) {
      let params = {};
      if (this.group != null && this.group.institutionCode != null) {
        params = {
          institutionCodes: [this.group.institutionCode],
          groupId: this.group.id,
          startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
          limit: ctx.perPage,
        };
      } else if (this.selectedInstitution && this.selectedInstitution.institutionCode) {
        params = {
          institutionCodes: this.paramInstitutions,
          startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
          limit: ctx.perPage,
        };
      }
      return params;
    },
    prepareMediaItems(response) {
      const reportedMedia = response.data.data.reportedMedia;
      this.reportedMediaItems = reportedMedia.map(current => {
        const reportedContentItem = Object.assign({}, current);
        reportedContentItem.hasBadge =
          this.notifications.find(
            n =>
              n.mediaId === reportedContentItem.id &&
              n.notificationType === notificationTypes.BADGE &&
              n.notificationEventType === notificationEventTypes.MEDIA_WAS_REPORTED
          ) != null;
        reportedContentItem.reporter = Vue.filter('displayProfileNameWithMetadata')(
          reportedContentItem.mediaReport.reportedBy
        );
        reportedContentItem.reportedTime = reportedContentItem.mediaReport.reportedAt;
        reportedContentItem.author = Vue.filter('displayProfileNameWithMetadata')(reportedContentItem.creator);
        reportedContentItem.link = [
          {
            textkey: 'REPORTED_CONTENT_LINK_LABEL',
            variant: 'link',
            method: 'previewMedia',
          },
        ];
        reportedContentItem.reason = reportedContentItem.mediaReport.reason;

        reportedContentItem.actions = [
          {
            variant: 'link',
            method: 'acceptReportedMediaConfirm',
            textkey: 'REPORTED_CONTENT_FIELDS_ACCEPT',
          },
          {
            variant: 'link',
            method: 'rejectReportedMediaConfirm',
            textkey: 'REPORTED_CONTENT_FIELDS_REJECT',
          },
        ];

        return reportedContentItem;
      });
      return this.reportedMediaItems;
    },
    prepareCommentParams(ctx) {
      let params = {};
      if (this.group != null) {
        params = {
          institutionCodes: [this.group.institutionCode],
          groupId: this.group.id,
          startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
          limit: ctx.perPage,
        };
      } else if (this.selectedInstitution && this.selectedInstitution.institutionCode) {
        params = {
          institutionCodes: this.paramInstitutions,
          startIndex: ctx.currentPage * ctx.perPage - ctx.perPage,
          limit: ctx.perPage,
        };
      }
      return params;
    },
    prepareCommentItems(response) {
      const reportedComments = response.data.data.comments;
      this.reportedCommentItems = reportedComments.map(current => {
        const reportedContentItem = Object.assign({}, current);
        reportedContentItem.hasBadge =
          this.notifications.find(
            n =>
              n.commentId === reportedContentItem.id &&
              n.notificationType === notificationTypes.BADGE &&
              n.notificationEventType === notificationEventTypes.COMMENT_WAS_REPORTED
          ) != null;
        reportedContentItem.reporter = Vue.filter('displayProfileNameWithMetadata')(
          reportedContentItem.report.reportedBy
        );
        reportedContentItem.reportedTime = reportedContentItem.report.reportedAt;
        reportedContentItem.author = Vue.filter('displayProfileNameWithMetadata')(reportedContentItem.creator);
        reportedContentItem.link = [
          {
            textkey: 'REPORTED_CONTENT_LINK_LABEL',
            variant: 'link',
            method: 'previewComment',
          },
        ];
        reportedContentItem.reason = reportedContentItem.report.reason;

        reportedContentItem.actions = [
          {
            variant: 'link',
            method: 'acceptReportedCommentConfirm',
            textkey: 'REPORTED_CONTENT_FIELDS_ACCEPT',
          },
          {
            variant: 'link',
            method: 'rejectReportedCommentConfirm',
            textkey: 'REPORTED_CONTENT_FIELDS_REJECT',
          },
        ];

        return reportedContentItem;
      });
      return this.reportedCommentItems;
    },
    acceptReportedPostConfirm(item = null) {
      if (item != null) {
        this.selectedPost = item.item;
        this.deletePostNotifications(this.selectedPost.id);
      }
      this.$refs.deletePostModal.show();
    },
    rejectReportedPostConfirm(item = null) {
      if (item != null) {
        this.selectedPost = item.item;
        this.deletePostNotifications(this.selectedPost.id);
      }
      this.$refs.postModal.hide();
      this.rejectReportedPost({
        id: this.selectedPost.id,
        institutionCode: this.institutionCode,
      }).then(() => {
        this.$refs.reportedPostsResult.refresh();
      });
    },
    acceptReportedMediaConfirm(item = null) {
      if (item != null) {
        this.selectedMedia = item.item;
        this.deleteMediaNotifications(this.selectedMedia.id);
      }
      this.$refs.deleteMediaModal.show();
    },
    rejectReportedMediaConfirm(item = null) {
      if (item != null) {
        this.selectedMedia = item.item;
        this.deleteMediaNotifications(this.selectedMedia.id);
      }
      this.$refs.mediaModal.hide();
      this.rejectReportedMedia({
        id: this.selectedMedia.id,
        institutionCode: this.institutionCode,
      }).then(() => {
        this.$refs.reportedMediaResult.refresh();
      });
    },
    acceptReportedCommentConfirm(item = null) {
      if (item != null) {
        this.selectedComment = item.item;
        this.deleteCommentNotifications(this.selectedComment.id);
      }
      this.$refs.deleteCommentModal.show();
    },
    rejectReportedCommentConfirm(item = null) {
      if (item != null) {
        this.selectedComment = item.item;
        this.deleteCommentNotifications(this.selectedComment.id);
      }
      this.$refs.commentModal.hide();
      this.rejectReportedComment({
        id: this.selectedComment.id,
        institutionCode: this.institutionCode,
      }).then(() => {
        this.$refs.reportedCommentsResult.refresh();
      });
    },
    previewPost(item) {
      this.selectedPost = item.item;
      this.deletePostNotifications(this.selectedPost.id);
      this.$refs.postModal.show();
      this.showPostMediaDetail = false;
    },
    previewMedia(item) {
      this.selectedMedia = item.item;
      this.deleteMediaNotifications(this.selectedMedia.id);
      this.$refs.mediaModal.show();
    },
    previewComment(item) {
      this.showPostMediaDetail = false;
      this.selectedComment = item.item;
      this.deleteCommentNotifications(this.selectedComment.id);
      this.highlightComment.id = this.selectedComment.id;
      if (this.selectedComment.parentCommentId) {
        this.highlightComment.parentId = this.selectedComment.parentCommentId;
      }
      if (this.selectedComment.postId != null) {
        this.loadRelatedPost({ postId: this.selectedComment.postId }).then(() => {
          this.$refs.commentModal.show();
        });
      } else if (this.selectedComment.mediaId != null) {
        this.loadRelatedMedia({ mediaId: this.selectedComment.mediaId }).then(() => {
          this.$refs.commentModal.show();
        });
      }
    },
    acceptReportedPost() {
      this.toggleSpinner({
        promises: [
          this.removeReportedPost({
            id: this.selectedPost.id,
            institutionCode: this.institutionCode,
          }),
        ],
      }).then(() => {
        this.loadNotifications({
          activeInstitutionCodes: [this.selectedInstitution.institutionCode],
        });
        this.$refs.reportedPostsResult.refresh();
      });
      this.$refs.deletePostModal.hide();
      this.$refs.postModal.hide();
    },
    acceptReportedMedia() {
      this.toggleSpinner({
        promises: [
          this.removeReportedMedia({
            id: this.selectedMedia.id,
            institutionCode: this.institutionCode,
          }),
        ],
      }).then(() => {
        this.$refs.reportedMediaResult.refresh();
      });
      this.$refs.deleteMediaModal.hide();
      this.$refs.mediaModal.hide();
    },
    acceptReportedComment() {
      this.toggleSpinner({
        promises: [
          this.removeReportedComment({
            id: this.selectedComment.id,
            institutionCode: this.institutionCode,
          }),
        ],
      }).then(() => {
        this.$refs.reportedCommentsResult.refresh();
      });
      this.$refs.deleteCommentModal.hide();
      this.$refs.commentModal.hide();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.reported-content-container {
  .b-table {
    th:first-child,
    td:first-child {
      font-weight: normal;
    }
  }
  tbody tr {
    &:hover {
      button {
        color: $color-white;
      }
    }
  }
}

.preview-container {
  min-width: 400px;
  padding-bottom: 5px;
  /deep/ .card-text {
    padding-left: 25px;
  }

  /deep/ video {
    width: 800px;
  }
}

.aula-close {
  right: 20px;
  position: absolute;
  top: 0;
  cursor: pointer;
}
</style>
