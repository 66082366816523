import { portal } from '../../assets/plugins/axios/axios';
import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { EsdhDataExportConfigurationModel } from '../../models/esdhDataExportConfiguration.model';
import { AddEsdhDataExportRecipientRequest } from '../../models/requests/addEsdhDataExportRecipient.request';
import { UpdateEsdhDataExportRecipientRequest } from '../../models/requests/updateEsdhDataExportRecipient.request';
import { DeleteEsdhDataExportRecipientRequest } from '../../models/requests/deleteEsdhDataExportRecipient.request';
import { GetExportDataRequest } from '../../models/requests/getExportData.request';
import { GetExportDataForMunicipalityRequest } from '../../models/requests/getExportDataForMunicipality.request';
import { EsdhExportDataModel } from '../../models/esdhExportData.model';

class EsdhDataExportAdminService {
  public getEsdhDataExportConfiguration = async (
    municipalityCode: string
  ): Promise<EsdhDataExportConfigurationModel> => {
    try {
      const { data } = await portal.get('?method=EsdhDataExportAdmin.getEsdhDataExportConfiguration', {
        params: { municipalityCode },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public addEsdhDataExportRecipient = async (payload: AddEsdhDataExportRecipientRequest) => {
    try {
      await portal.post('?method=EsdhDataExportAdmin.addEsdhDataExportRecipient', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public updateEsdhDataExportRecipient = async (payload: UpdateEsdhDataExportRecipientRequest) => {
    try {
      await portal.post('?method=EsdhDataExportAdmin.updateEsdhDataExportRecipient', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public deleteEsdhDataExportRecipient = async (payload: DeleteEsdhDataExportRecipientRequest) => {
    try {
      await portal.post('?method=EsdhDataExportAdmin.deleteEsdhDataExportRecipient', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getRecipientNames = async (institutionCode: string): Promise<string[]> => {
    try {
      const { data } = await portal.get('?method=EsdhDataExportAdmin.getRecipientNames', {
        params: { institutionCode },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getExportData = async (params: GetExportDataRequest): Promise<EsdhExportDataModel> => {
    try {
      const { data } = await portal.get('?method=EsdhDataExportAdmin.getExportData', {
        params,
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getExportDataForMunicipality = async (
    params: GetExportDataForMunicipalityRequest
  ): Promise<EsdhExportDataModel> => {
    try {
      const { data } = await portal.get('?method=EsdhDataExportAdmin.getExportDataForMunicipality', {
        params,
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public journalizeDocuments = async (payload: {
    dataExportIds: number[];
    institutionCode: string;
  }): Promise<string> => {
    try {
      const { data } = await portal.post('?method=EsdhDataExportAdmin.journalizeDocuments', payload);
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public markDocumentsForManualTransfer = async (payload: { dataExportIds: number[]; municipalityCode: number }) => {
    try {
      const { data } = await portal.post('?method=EsdhDataExportAdmin.markDocumentsForManualTransfer', payload);
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public resendDocuments = async (payload: { dataExportIds: number[]; municipalityCode: number }) => {
    try {
      const { data } = await portal.post('?method=EsdhDataExportAdmin.resendDocuments', payload);
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const esdhDataExportAdminService = new EsdhDataExportAdminService();
