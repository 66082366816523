import { moduleWidgetPlacements } from '../enums/moduleWidgetPlacements';
import { moduleTypes } from '../enums/moduleTypes';
import { permissionEnum } from '../enums/permissionEnum';
import { menuTypeEnum } from '../enums/menuTypeEnum';
import { menuItem } from '../enums/menuItem';
import { notificationAreas } from '../enums/notificationAreas';
import { iconClassEnum } from '../enums/iconClassEnum';
type menuItemType = {
  canBePlacedOnGroup: boolean;
  description: string;
  icon: string;
  iconImage: null | string;
  id: number;
  name: string;
  order: number;
  permissions: permissionEnum[];
  placement: moduleWidgetPlacements;
  show: boolean;
  type: string;
  url: string;
};
type menuPlacementDictionary = Record<moduleWidgetPlacements, menuItemType[]>;
class MenuUtil {
  getMenuItemsList(
    menuPlacementDictionary: menuPlacementDictionary,
    placements: moduleWidgetPlacements[]
  ): menuItemType[] {
    const menus = [];
    for (const menuPlacement of placements) {
      const menuItemsFromPlacement = menuPlacementDictionary[menuPlacement] || [];
      menus.push(...menuItemsFromPlacement);
    }
    return menus;
  }

  getIsAdministrativeMenu(menu: menuItemType) {
    return this.getIsAdministrationMenu(menu) || this.getIsOTPAdministrationMenu(menu);
  }

  getIsAdministrationMenu(menu: menuItemType) {
    return menu.type === moduleTypes.ADMINISTRATION;
  }

  getIsOTPAdministrationMenu(menu: menuItemType) {
    return menu.type === moduleTypes.OTP_ADMINISTRATION;
  }

  getSortedMenuItems(menus: menuItemType[]) {
    const copiedMenus = [...menus];
    return copiedMenus.sort((menuA, menuB) => {
      const isMenuAOverview = menuA.type === moduleTypes.OVERVIEW;
      const isMenuBOverview = menuB.type === moduleTypes.OVERVIEW;
      const isMenuAAdministrative = this.getIsAdministrativeMenu(menuA);
      const isMenuBAdministrative = this.getIsAdministrativeMenu(menuB);

      if (isMenuAOverview) {
        return -1;
      } else if (isMenuBOverview) {
        return 1;
      }

      if (isMenuAAdministrative) {
        return -1;
      } else if (isMenuBAdministrative) {
        return 1;
      }

      return 0;
    });
  }

  getIsIconImage(icon: string) {
    return icon.match(/\.(jpg|svg|jpeg|gif|png)/i) != null;
  }

  getMenuType(type) {
    switch (type) {
      case menuTypeEnum.OVERVIEW:
        return menuItem.OVERVIEW;
      case menuTypeEnum.CALENDAR:
        return menuItem.CALENDAR;
      case menuTypeEnum.MESSAGES:
        return menuItem.MESSAGES;
      case menuTypeEnum.DOCUMENTS:
        return menuItem.DOCUMENTS;
      case menuTypeEnum.GALLERY:
        return menuItem.GALLERY;
      case menuTypeEnum.PRESENCE:
        return menuItem.PRESENCE;
      case menuTypeEnum.ADMINISTRATION:
        return menuItem.ADMINISTRATION;
      case menuTypeEnum.CONTACTS:
        return menuItem.CONTACTS;
      case menuTypeEnum.PERSONAL_REFERENCE_DATA:
        return menuItem.PERSONAL_REFERENCE_DATA;
      case menuTypeEnum.OTP_ADMINISTRATION:
        return menuItem.OTP_ADMINISTRATION;
      default:
        return '';
    }
  }

  getNotificationAreaFromModule(module) {
    let notificationArea = null;
    switch (module) {
      case moduleTypes.OVERVIEW:
        notificationArea = notificationAreas.POSTS;
        break;
      case moduleTypes.CALENDAR:
        notificationArea = notificationAreas.CALENDAR;
        break;
      case moduleTypes.MESSAGES:
        notificationArea = notificationAreas.MESSAGES;
        break;
      case moduleTypes.ADMINISTRATION:
        notificationArea = notificationAreas.ADMINISTRATION;
        break;
      case moduleTypes.GALLERY:
        notificationArea = notificationAreas.GALLERY;
        break;
      case moduleTypes.DOCUMENTS:
        notificationArea = notificationAreas.DOCUMENTS;
        break;
      case moduleTypes.PRESENCE:
        notificationArea = notificationAreas.PRESENCE;
        break;
    }
    return notificationArea;
  }

  getMenuIconClass(menu: menuItemType) {
    switch (menu.type) {
      case menuTypeEnum.MESSAGES:
        return iconClassEnum.CONVERSATION;
      case menuTypeEnum.PERSONAL_REFERENCE_DATA:
        return iconClassEnum.PERSONAL_DATA;
    }
    return menu.icon;
  }
}

export const menuUtil = new MenuUtil();
