export enum menuTypeEnum {
  OVERVIEW = 'overview',
  CALENDAR = 'calendar',
  MESSAGES = 'messages',
  DOCUMENTS = 'documents',
  GALLERY = 'gallery',
  PRESENCE = 'presence',
  ADMINISTRATION = 'administration',
  CONTACTS = 'contacts',
  PERSONAL_REFERENCE_DATA = 'personal_reference_data',
  OTP_ADMINISTRATION = 'otp_administration',
}
