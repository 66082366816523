<template>
  <div class="documents-toolbar-container">
    <nav
      v-if="!isMobile && profile.role !== portalRoles.GUARDIAN"
      class="documents-toolbar navbar navbar-expand-lg"
      tabindex="0"
    >
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <b-btn variant="link" class="tool-link" @click="$router.push({ name: 'createDocumentNote' })">
            <i class="icon icon-Aula_plus in-circle" />
            {{ 'DOCUMENTS_SHARED_CREATE_DOCUMENT_NOTE' | fromTextKey }}
          </b-btn>
        </li>
        <li class="nav-item">
          <b-btn variant="link" class="tool-link" @click="$router.push({ name: 'documentsSecureNew' })">
            <i class="icon icon-Aula_plus in-circle" />
            {{ 'DOCUMENTS_SHARED_CREATE_DOCUMENT' | fromTextKey }}
          </b-btn>
        </li>
        <li class="nav-item">
          <b-btn variant="link" class="tool-link" @click="$router.push({ name: 'documentsSecureImport' })">
            <i class="icon icon-Aula_attach_doc" />
            {{ 'DOCUMENTS_SHARED_IMPORT_FILE_LINK' | fromTextKey }}
          </b-btn>
        </li>
      </ul>
    </nav>
    <multi-button-mobile
      v-if="isMobile && profile.role !== portalRoles.GUARDIAN"
      :items="multiButtonItems"
      @newInternalFile="$router.push({ name: 'documentsSecureNew' })"
      @newNote="$router.push({ name: 'createDocumentNote' })"
      @newExternalFile="$router.push({ name: 'documentsSecureImport' })"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import MultiButtonMobile from '../shared/MultiButtonMobile';

export default {
  data: function () {
    return {
      portalRoles,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
    }),
    multiButtonItems() {
      const items = [];
      items.push({
        icon: 'icon-Aula_plus',
        text: 'DOCUMENTS_SHARED_CREATE_DOCUMENT_NOTE',
        action: 'newNote',
      });
      items.push({
        icon: 'icon-Aula_plus',
        text: 'DOCUMENTS_SHARED_CREATE_DOCUMENT',
        action: 'newInternalFile',
      });
      items.push({
        icon: 'icon-Aula_attach_doc',
        text: 'DOCUMENTS_SHARED_IMPORT_FILE_LINK',
        action: 'newExternalFile',
      });
      return items;
    },
  },
  mounted() {
    if (!this.isMobile) {
      setTimeout(function () {
        document.querySelector('.documents-secure-list-container .documents-toolbar').focus();
      }, 500);
    }
  },
  components: {
    MultiButtonMobile,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.icon-Aula_plus.in-circle {
  color: $color-white;
  .theme-employee & {
    background-color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    background-color: $color-primary-darker-guardian;
  }
  .theme-child & {
    background-color: $color-primary-darker-child;
  }
  .theme-admin & {
    background-color: $color-primary-darker-admin;
  }
}
.icon-Aula_attach_doc {
  font-size: 19px;
  line-height: 15px;
  position: relative;
  top: 3px;

  .tool-link & {
    font-size: 19px;
  }
}

.documents-toolbar {
  font-size: 14px;
  background-color: $color-grey-base;
  height: 50px;
  padding-left: 0;
  outline: none;

  .navbar-nav {
    flex-direction: row;
    gap: 4px;
  }
}
</style>
