export enum menuItem {
  OVERVIEW = 'MENU_OVERVIEW',
  CALENDAR = 'MENU_CALENDAR',
  MESSAGES = 'MENU_MESSAGES',
  DOCUMENTS = 'MENU_DOCUMENTS',
  GALLERY = 'MENU_GALLERY',
  PRESENCE = 'MENU_PRESENCE',
  ADMINISTRATION = 'MENU_ADMINISTRATION',
  CONTACTS = 'MENU_CONTACTS',
  PERSONAL_REFERENCE_DATA = 'MENU_PERSONAL_REFERENCE_DATA',
  OTP_ADMINISTRATION = 'MENU_OTP_ADMINISTRATION',
}
